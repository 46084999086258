@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orelega+One&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family: 'Poppins', sans-serif;
}


@media only screen and (max-width: 767px) {

  .mainForm {
    margin:10px ​5px !important;
  }
  
}
body {
  font-family: 'Poppins', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.about {
  margin-left: 15px;
}

.about a, .toplink p {
  color: #fff;
  margin: 0;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}


.MuiInputLabel-animated, .searchLabel {
  font-weight: 600;
  font-family: 'Poppins' !important;
  color: #000 !important;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.MuiInputBase-root {
  border:1px solid #ddd;
}

.MuiSelect-select {
  padding:10px !important;
}

.MuiInput-underline:before {
  content:none !important;
}

.list-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}


.list-group .gridBox {
  width: 49% !important;
  float: left;
  border: 1px solid #ddd !important;
  box-shadow: 0 0 12px #ddd;
  margin-bottom: 30px !important;
  padding:20px !important;
  cursor:inherit;
}


.infolabel {
  min-width: 175px;
}

.terms {
  margin: 0 30px;
}

.terms ol li {
  margin-bottom: 15px;
}


.addform {
  width: 75%;
  border: 1px solid #ddd;
  margin: 40px auto !important;
  padding: 20px;
  box-shadow: 0 0 9px #ddd;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

.addlabel label {
  padding-left: 10px;
  padding-top: 1px;
}

.adddate {
  padding-left: 1px;
  padding-top: 1px;
  width: 30px;
}

.adddateyear {
  padding-left: 1px;
  padding-top: 1px;
  width: 50px;
}

.addpageSelect {
  width: 250px !important;
  margin-right: 20px;
  margin-bottom: 25px;
  margin-top: 15px;
}

.submitBtn {
  width: 95px;
  height: 40px;
  margin-top: 45px !important;
}

.section-heading {
  background: #1B9DF0;
  color: #fff;
  padding: 8px 15px;
  border-radius: 50px;
}

.CovidInfosList-categoryButton-39, button {
  transition: 0.5s ease !important;
  font-family: 'Poppins', sans-serif !important; 
}

.CovidInfosList-categoryButtonSelected-40 {
  border-radius: 50px !important;
}

.appbar {
  background-color:#343A40 !important;
  top: 0;
}

.topbar {
  padding:0 16px !important;
}

.mainform {
  margin:20px 20px;
}

.MuiTouchRipple-root {
  display: none !important;
}

.gridBox.Mui-selected, .gridBox:hover {
  background-color: #fff !important;
}

.back_btn {
  background: #747474 !important;
  color: #fff !important;
  border-radius: 0 !important;
  padding:6px 10px !important;
  margin-right: 13px !important;
  transition: 0.5s ease !important;
  margin-top: 10px !important;
}


.whatsapp_btn {
  background: #28A745 !important;
  
  
  border-radius: 0 !important;
  padding:6px 10px !important;
  margin-right: 13px !important;
  transition: 0.5s ease !important;
  margin-top: 10px !important;
}
.whatsapp_btn a{
  color: #fff !important;
  text-decoration: none !important;
}
.twitter_btn a{
  color: #fff !important;
  text-decoration: none !important;
}
.twitter_btn {
  background: #00acee !important;

  border-radius: 0 !important;
  margin-right: 13px !important;
  padding:6px 10px !important;
  transition: 0.5s ease !important;
  margin-top: 10px !important;
}

.back_btn:hover{
  border-radius: 50px !important;
}

.whatsapp_btn:hover{
  border-radius: 50px !important;
}
.twitter_btn:hover{
  border-radius: 50px !important;
}
 .contact-div a{
  margin-right: 7px;
 }

.footerbar {
  background-color: #343A40 !important;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
}

.footerimg {
  margin-top: 5px;
}

#root {
  position: relative;
  padding-bottom: 50px;
}

.supported {
  color: #fff;
  text-align: right;
  margin-left: 15px;
}

.supported a {
  color: #D0F85A;
  font-size: 25px;
  font-family: 'Orelega One', cursive;
  letter-spacing: 1px;
  margin-left: 5px;
}

.custom-logo h2 {
  font-family: 'Orelega One', cursive;
  font-size: 38px;
  margin: 0;
  font-weight: 400;
  /* color:#F7E077; */
   color: #D0F85A;
}

.custom-logo h2 a {
  color: #D0F85A;
  text-decoration: none;
}

.custom-logo {
  margin-right: 30px;
}

.floating-div {
  position: fixed;
  right: 10px;
  bottom: 15px;
}

.float_btn {
  background: #28A745;
  padding: 10px;
  display: block;
  text-decoration: none;
  color: #fff;
  font-family: 'Poppins' !important;
  margin-bottom: 5px;
  position: relative;
  transition: 0.5s ease;
  right: -10px;
  border-radius: 8px;
}

.float_btn i {
  color: #fff;
  font-size: 19px;
}

.search_btn {
  margin: 6px 10px 0;
}

.search-icon {
  display: none !important;
}

.table {
  width: 100%;
  margin:0;
}

.table tr th, .table tr td {
  border: 1px solid #ddd;
  margin: 0;
  text-align: center;
}

.filter-div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.filter-div h3 {
  color: #28A745;
  font-size: 22px;
  margin: 10px 0;
}

.filter {
  margin: 0 15px;
}

.filter label {
  font-weight: 600;
  margin-right: 2px;
}

.form-control {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 10px;
}

/* responsive start */

@media only screen and (max-width: 767px) {

    .list-group .gridBox {
      width: 100% !important;
      padding: 25px 10px 10px !important;
    }


    .mobilelogo {
      width: 100%;
      overflow: visible;
      margin: 5px auto;
      text-align: center;
    }

    .toplink {
      margin: 0 10px !important;
    }

    .topbar {
      padding: 0 10px !important;
      min-height: 50px !important;
    }

    /* .mobile-search {
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center;
      margin:0 0 30px !important;
    } */

    /* .mobstate {
        margin: 0 0 20px !important;
    } */

    .category-btn {
      margin: 2px !important;
      padding: 5px 7px !important; 
    }

    .infolabel {
      min-width: 100px;
    }

    .addinput {
      width: 100%;
      margin-left: 0 !important;
   }

   .addlabel, .addpageSelect {
      width: 250px !important;
      margin:10px 0;
   }

   .submitBtn {
      margin-top: 10px !important;
    }

    .mainform {
      margin:10px;
    }

    .list-group .gridBox:nth-child(even) {
      background-color:rgba(88, 103, 221, 0.2);
    }
    
    .list-group .gridBox:nth-child(even):hover {
      background-color: rgba(88, 103, 221, 0.2) !important;
    }

    .custom-logo {
      margin-right: 0px;
    }

    .custom-logo h2 {
      font-size: 32px;
    }

    .floating-div {
      width: 100%;
      bottom: 0;
    }

    .inner-buttons {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: center;
              justify-content: center;
    }

    .float_btn {
      margin-right: 5px;
      padding: 7px;
    }

    #root {
      position: relative;
      padding-bottom: 100px;
   }

   .footerbar {
    bottom: 60px;
    height: auto !important;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .supported {
    margin: 0 0 6px;
  }

    .searchbtn {
      display: none !important;
    }

    .search-icon {
      display: inline-block !important;
      padding: 7px 10px !important;
      margin: 8px -3px !important;
      min-width: auto !important;
    }

    .filter-div {
      display: inline-block;
    }

    .filter {
      margin: 5px 0;
    }

    .table-responsive {
      overflow: scroll;
    }

    
    


  


}
